<template>
  <div class="page">
    <mu-appbar title="Opensource-Comm" titleClass="left">
      <mu-flat-button color="white" label="Welcome to Opensource Comm!" slot="right"/>
    </mu-appbar>
    <div class="content">
      <!-- <div class="content-left">
        <mu-raised-button class="chat-btn russia" fullWidth primary href="https://ru.linuxcomm.club" target="_blank">
          <div class="chat-btn-nested">
            <h5><mu-icon value="chat"></mu-icon>Chat Portal</h5>
            <p><span class="flag-icon flag-icon-ru"></span><span class="label">Russia Server</span></p>
          </div>
        </mu-raised-button>
        <mu-raised-button class="chat-btn us" fullWidth primary href="https://us.linuxcomm.club" target="_blank">
          <div class="chat-btn-nested">
            <h5><mu-icon value="chat"></mu-icon>Chat Portal</h5>
            <p><span class="flag-icon flag-icon-us"></span><span class="label">The US Server</span></p>
          </div>
        </mu-raised-button>
        <mu-raised-button class="chat-btn us" fullWidth primary href="https://jp.linuxcomm.club" target="_blank">
          <div class="chat-btn-nested">
            <h5><mu-icon value="chat"></mu-icon>Chat Portal</h5>
            <p><span class="flag-icon flag-icon-jp"></span><span class="label">Japan Server</span></p>
          </div>
        </mu-raised-button>
      </div> -->
      <div class="content-right">
        <mu-card class="main-card">
          <mu-card-title
            title="Hack News"
            titleClass="text-align-left"
            subTitle="Top Stories"
            subTitleClass="text-align-left"
          />
          <mu-card-text>
            <div class="loading" v-if="showLoading">
              <mu-linear-progress />
              <h4>Loading...</h4>
            </div>
            <mu-list>
              <template v-for="(story, index) in topStories">
                <!-- <mu-list-item
                  :key="story.id"
                  :title="story.title"
                  titleClass="text-align-left"
                  :afterText="story.time.toDateString()"
                  inset
                  toggleNested
                  :open="false"
                > -->
                <mu-list-item
                  :key="story.id"
                  :title="story.title"
                  titleClass="text-align-left"
                  :afterText="timeago(story.time)"
                  inset
                  :href="story.url"
                  target="_blank"
                >
                  <mu-avatar color="white" :backgroundColor="randomAvatarColor()" slot="leftAvatar">{{story.title.slice(0, 1)}}</mu-avatar>
                  <p slot="describe" class="author"><strong>by: </strong>{{story.by}}</p>
                  <!-- <div class="story-detail" slot="nested">
                    <a :href="story.url" target="_blank"><mu-icon value="link"/><span>Show Story</span></a>
                  </div> -->
                </mu-list-item>
                <mu-divider :key="story.id" inset v-if="index < topStories.length - 1"/>
              </template>
            </mu-list>
          </mu-card-text>
        </mu-card>
      </div>
    </div>
    <mu-float-button icon="refresh" class="refresh-btn" @click="refresh"/>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
const avatarColors = ['red500', 'pink500', 'purple500', 'indigo500', 'blue500', 'lightBlue500', 'teal500', 'green500', 'lightGreen500', 'orange500']
const MINUTE = 60
const HOUR = MINUTE * 60
const DAY = HOUR * 24
const WEEK = DAY * 7
const MONTH = DAY * 30
const YEAR = DAY * 365
function pluralOrSingular (data, locale) {
  if (data === 'just now') {
    return locale
  }
  const count = Math.round(data)
  if (Array.isArray(locale)) {
    return count > 1
      ? locale[1].replace(/%s/, count)
      : locale[0].replace(/%s/, count)
  }
  return locale.replace(/%s/, count)
}
export default {
  name: 'PageIndex',
  async created () {
    this.showLoading = true
    await this.getTopStories(10)
    this.showLoading = false
  },
  data () {
    return {
      showLoading: false
    }
  },
  computed: {
    ...mapState([
      'topStories'
    ])
  },
  methods: {
    ...mapActions([
      'getTopStories'
    ]),
    randomAvatarColor () {
      return avatarColors[Math.floor(Math.random() * 10)]
    },
    timeago (time) {
      const seconds = Date.now() / 1000 - time.getTime() / 1000
      const ret =
          seconds <= 5
            ? pluralOrSingular('just now', 'just now')
            : seconds < MINUTE
              ? pluralOrSingular(seconds, ['%s second ago', '%s seconds ago'])
              : seconds < HOUR
                ? pluralOrSingular(seconds / MINUTE, ['%s minute ago', '%s minutes ago'])
                : seconds < DAY
                  ? pluralOrSingular(seconds / HOUR, ['%s hour ago', '%s hours ago'])
                  : seconds < WEEK
                    ? pluralOrSingular(seconds / DAY, ['%s day ago', '%s days ago'])
                    : seconds < MONTH
                      ? pluralOrSingular(seconds / WEEK, ['%s week ago', '%s weeks ago'])
                      : seconds < YEAR
                        ? pluralOrSingular(
                            seconds / MONTH,
                            ['%s month ago', '%s months ago']
                          )
                        : pluralOrSingular(
                            seconds / YEAR,
                            ['%s year ago', '%s years ago']
                          )
      return ret
    },
    async refresh () {
      this.showLoading = true
      await this.getTopStories(10)
      this.showLoading = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.content {
  display: flex;
  width: 100vw;
}
.content-left {
  width: 240px;
  flex: none;
  padding: 20px;
}

.content-right {
  flex: auto;
  padding: 20px;
}

.loading h4 {
  color: #666666;
  text-align: left;
}

.author {
  color: #333;
  text-align: left;
  margin: 6px 0 -2px 0;
}

.story-detail {
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 72px;

  a {
    display: block;
    i {
      float: left;
    }
    span {
      line-height: 26px;
      margin-left: 8px;
    }
  }
}

.chat-btn {
  min-height: 80px;
  margin-bottom: 24px;
}

.chat-btn-nested {
  display: block;
  width: 100%;
  height: 100%;

  h5 {
    font-size: 15px;
    line-height: 38px;
    border-bottom: 1px solid #999;
    margin: 4px 0 4px 0;

    i {
      font-size: 16px;
      padding-right: 8px;
    }
  }

  p {
    line-height: 24px;
    margin: 0;

    .label {
      font-size: 12px;
      margin-left: 8px;
    }
  }
}

.refresh-btn {
  position: fixed;
  bottom: 42px;
  right: 28px;
}
</style>
