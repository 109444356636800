import Vue from 'vue'
import Vuex from 'vuex'

import hyperquest from 'hyperquest'

Vue.use(Vuex)

const state = {
  topStoriesIds: [],
  topStories: []
}

const mutations = {
  updateTopStoriesIds (state, storiesIds) {
    state.topStoriesIds = storiesIds
  },
  updateTopStories (state, story) {
    if (!state.topStories.find(item => item.id === story.id)) {
      state.topStories.push(story)
    }
  }
}

const actions = {
  getTopStoriesIds ({ commit }, account = 40) {
    return new Promise((resolve, reject) => {
      hyperquest('https://hacker-news.firebaseio.com/v0/topstories.json').on('data', data => {
        const topStories = JSON.parse(data)
        commit('updateTopStoriesIds', topStories.slice(0, account))
        resolve()
      })
    })
  },
  async getStory (context, id) {
    return new Promise((resolve, reject) => {
      const stream = hyperquest(`https://hacker-news.firebaseio.com/v0/item/${id}.json?print=pretty`)
      stream.on('data', data => {
        resolve(JSON.parse(data))
      })
      stream.on('error', err => {
        reject(err)
      })
    })
  },
  async getTopStories ({ state, commit, dispatch }, account = 40) {
    await dispatch('getTopStoriesIds', account)
    const promises = state.topStoriesIds.map(id => dispatch('getStory', id))
    for (let promise of promises) {
      try {
        const story = await promise
        commit('updateTopStories', Object.assign(story, {
          time: new Date(story.time * 1000)
        }))
      } catch (err) {}
    }
    return Promise.resolve()
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions
})
